import axios from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchEmployees(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/users", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchRolesList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/roles", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchShiftsList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/shifts", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchDesignationsList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/designations", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchDivisionList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/divisions", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchDepartmentList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/departments", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchEmployee(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/user/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        addEmployee(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/user", userData)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        bulkUploadEmployees(ctx, interviewData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/usersbulkupload", interviewData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        updateEmployee(ctx, employee) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/user/${employee.userId}`, employee.data)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[i][0]);
                                    }
                                }
                            }
                            else
                            {
                                reject(error.response.data.message);
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        removeEmployee(ctx, userId) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/user/${userId}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        removeMultipleEmployees(ctx, ids) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/deleteMultipleUsers/", { users: ids })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        fetchChecklists(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/checklist", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        addChecklist(ctx, checklistData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/checklist", checklistData)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        updateChecklist(ctx, checklist) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/checklist/${checklist.userId}`, checklist.data)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        updateCandidateStatus(ctx, candidate) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/candidate/statusUpdate/${candidate.id}`, {
                        status: 1
                    })
                    .then(response => {
                        resolve(response);
                    });
            });
        },
        removeChecklist(ctx, checklistId) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/checklist/${checklistId}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        fetchChecklist(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/checklist/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        addDesignation(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/designations", { name: queryParams.name })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        removeDesignation(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/designations/${queryParams.id}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        addDivision(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/divisions", { name: queryParams.name })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        removeDivision(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/divisions/${queryParams.id}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        addDepartment(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/departments", { name: queryParams.name })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        removeDepartment(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/departments/${queryParams.id}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
    }
};
