<template>
  <div>
    <b-card-actions
      title="Filters"
      no-body
      class="mb-0"
      actionRefresh
      actionCollapse
      @refresh="triggerRefresh"
    >
      <employees-list-filters
        :role-filter.sync="roleFilter"
        :role-options="list_roles"
        :status-filter.sync="statusFilter"
        :status-options="statusOptions"
        :filter-by-designation-id.sync="filterByDesignationId"
        :designation-options.sync="designationOptions"
      />
    </b-card-actions>
    <!-- Filters -->
    <!-- <app-collapse hover>
      <app-collapse-item title="Filters">
        <employees-list-filters
          :role-filter.sync="roleFilter"
          :role-options="list_roles"
          :status-filter.sync="statusFilter"
          :status-options="statusOptions"
          :filter-by-designation-id.sync="filterByDesignationId"
          :designation-options.sync="designationOptions"
        />
      </app-collapse-item>
    </app-collapse> -->

    <!-- Table Container Card -->
    <b-card no-body class="mb-0 mt-2">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox
              v-if="usersMetaData.length && $can('employee_delete')"
              class="mr-2"
              v-model="isDeleteMultipleChecked"
            >
            </b-form-checkbox>
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              :searchable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="8">
            <div
              v-if="selectedEmployees.length == 0"
              class="d-flex align-items-center justify-content-end"
            >
              <b-input-group class="input-group-merge" v-if="!isFileSelected">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search Name/Email"
                />
              </b-input-group>
              <b-button
                v-if="!isFileSelected && $can('employee_create')"
                variant="primary"
                :to="{ name: 'add-employee' }"
              >
                <span class="text-nowrap">Add Employee</span>
              </b-button>
            </div>

            <b-button
              v-else-if="$can('employee_delete')"
              class="float-right"
              variant="danger"
              :disabled="employeeDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ employeeDeleteCount }} Employees</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-form-checkbox-group
        id="checkbox-group"
        v-model="selectedEmployees"
        name="selectedEmployees"
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchUsers"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(select)="data">
            <div class="text-nowrap">
              <b-form-checkbox :value="data.item.hashid"> </b-form-checkbox>
            </div>
          </template>

          <!-- Column: User -->
          <template #cell(name)="data">
            <b-media vertical-align="center" class="d-flex align-items-center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :text="avatarText(data.item.name)"
                  :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                />
                <!-- :to="{
                    name: 'view-employee',
                    params: { id: data.item.hashid },
                  }" -->
              </template>
              <b-link
                v-if="$can('employee_show')"
                :to="{
                  name: 'view-employee',
                  params: { id: data.item.hashid },
                }"
                class="font-weight-bold d-inline-block text-nowrap"
                v-b-tooltip.hover.v-default
                :title="data.item.name"
              >
                {{ data.item.name | str_limit(15) }}
              </b-link>
              <span
                v-else
                class="d-block text-nowrap"
                v-b-tooltip.hover.v-default
                :title="data.item.name"
              >
                {{ data.item.name | str_limit(15) }}
              </span>
            </b-media>
          </template>

          <!-- Column: User -->
          <template #cell(email)="data">
            <span v-b-tooltip.hover.v-default :title="data.item.email">
              {{ data.item.email | str_limit(15) }}
            </span>
          </template>

          <!-- Column: Manager -->
          <template #cell(manager)="data">
            <b-media vertical-align="center" class="d-flex align-items-center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.manager_avatar"
                  :text="avatarText(data.item.manager)"
                  :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                />
              </template>
              <b-link
                v-if="$can('employee_show')"
                :to="{
                  name: 'view-employee',
                  params: { id: data.item.manager_hashid },
                }"
                class="font-weight-bold d-inline-block text-nowrap"
                v-b-tooltip.hover.v-default
                :title="data.item.manager"
              >
                {{ data.item.manager | str_limit(15) }}
              </b-link>
              <span
                v-else
                class="d-block text-nowrap"
                v-b-tooltip.hover.v-default
                :title="data.item.manager"
              >
                {{ data.item.manager | str_limit(15) }}
              </span>
            </b-media>
          </template>
          <!-- Column: Role -->
          <template #cell(role)="data">
            <div class="text-nowrap d-flex align-items-center">
              <feather-icon
                :icon="resolveUserRoleIcon(data.item.role)"
                size="18"
                class="mr-50"
                :class="`text-${resolveUserRoleVariant(data.item.role)}`"
              />
              <span class="align-text-top text-capitalize">{{
                data.item.role
              }}</span>
            </div>
          </template>

          <!--Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
              class="text-capitalize"
            >
              {{ data.item.status ? "Active" : "In Active" }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <feather-icon
                @click="editRecord(data)"
                v-if="$can('employee_edit')"
                v-b-tooltip.hover
                title="Edit"
                icon="EditIcon"
                size="16"
                class="mr-1 cursor-pointer"
              />
              <feather-icon
                @click="viewRecord(data)"
                v-if="$can('employee_show')"
                v-b-tooltip.hover
                title="View"
                icon="EyeIcon"
                size="16"
                class="cursor-pointer"
              />

              <b-dropdown
                variant="link"
                size="sm"
                toggle-class="text-decoration-none"
                no-caret
                v-if="$can('employee_edit') || $can('employee_delete')"
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  class="btn-sm p-0"
                  v-if="$can('employee_edit')"
                  @click="passwordReset(data.item)"
                >
                  <feather-icon icon="SendIcon" size="14" class="mr-50" />
                  <span>Password Reset</span>
                </b-dropdown-item>

                <b-dropdown-item
                  class="btn-sm p-0"
                  @click="confirmDeleteRecord(data)"
                  v-if="$can('employee_delete')"
                >
                  <feather-icon icon="TrashIcon" size="14" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
      </b-form-checkbox-group>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-if="selectedEmployees.length == 0"
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <b-button
              v-else-if="$can('employee_delete')"
              class="float-right"
              variant="danger"
              :disabled="employeeDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ employeeDeleteCount }} Employees</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BButton,
  BBadge,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BFormCheckbox,
  BFormCheckboxGroup,
  BPagination,
  BButtonToolbar,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import employeeStoreModule from "../employeeStoreModule";
import useEmployeesList from "./useEmployeesList";
import EmployeesListFilters from "./EmployeesListFilters.vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import BCardActionsContainer from "@core/components/b-card-actions/BCardActionsContainer.vue";

// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    EmployeesListFilters,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BButton,
    BBadge,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    AppCollapse,
    AppCollapseItem,
    BFormCheckbox,
    BFormCheckboxGroup,
    vSelect,
    /* eslint-disable */
    ToastificationContent,
    FilePond,
    /* eslint-enable */
    BButtonToolbar,
    BDropdown,
    BDropdownItem,
    BCardActions,
    BCardActionsContainer,
  },
  setup() {

    const USER_APP_STORE_MODULE_NAME = "app-employees";
    const isDeleteMultipleChecked = ref(false);
    const selectedEmployees = ref([]);
    const employeeDeleteCount = ref(0);
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, employeeStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const statusOptions = [
      { label: "All", value: null },
      { label: "Active", value: "1" },
      { label: "In Active", value: "0" },
    ];

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      usersMetaData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      statusFilter,
      filterByDesignationId,
    } = useEmployeesList();

    watch(isDeleteMultipleChecked, (val) => {
      selectedEmployees.value = [];
      if (val) {
        usersMetaData.value.forEach((employee) => {
          selectedEmployees.value.push(employee.hashid);
        });
      }
    });

    watch(selectedEmployees, (val) => {
      employeeDeleteCount.value = val.length;
    });

  

    

    return {
      fetchUsers,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
      filterByDesignationId,
      isDeleteMultipleChecked,
      employeeDeleteCount,
      selectedEmployees,
      tableColumns,
      usersMetaData,
    };
  },
  data() {
    return {
      isFileSelected: false,
      uploadPath: process.env.VUE_APP_API_URL + "/usersbulkupload",
      list_roles: [],
      filter: {
        roleFilter: null,
      },
      designationOptions: [],
    };
  },
  created() {
    this.fetchRolesList();
    this.fetchDesignationsList();
  },
  watch: {
  '$route.query': function(val) {
    const filter = val.q; // Access the 'q' parameter directly from val
    if (filter) {
      this.searchQuery = filter; // Update the searchQuery data property
    }
  }
},
  methods: {
    passwordReset(user) {
      const self = this;

      let data = {};
      data.url = "/users/password-reset/" + user.hashid;

      self.$store
        .dispatch("app/store", data)
        .then((res) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Reset Password Notification sent",
          //     icon: "BellIcon",
          //     variant: "success",
          //   },
          // });

          this.$toast({
        component: ToastificationContent,
        props: {
          title: `Reset Password Notification sent`,
          icon:'BellIcon',
          variant : 'success',
          hideClose: true,

        },
      },
      {
        timeout : 2000,
        position : 'bottom-center',
        toastClassName:"successful-info",
        hideProgressBar : true,

      }) 
        })
        .catch((error) => {
          console.log(error);
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Reset Password Notification failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Reset Password Notification failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
    triggerRefresh() {
      this.roleFilter = 0;
      this.statusFilter = null;
      this.filterByDesignationId = "";
      this.showLoading = false;
    },
    editRecord(data) {
      this.$router.push(`/edit/employee/${data.item.hashid}`).catch(() => {});
    },
    viewRecord(data) {
      this.$router.push(`/view/employee/${data.item.hashid}`).catch(() => {});
    },
    fileSelected(e) {
      this.isFileSelected = !this.isFileSelected;
    },
    fetchDesignationsList() {
      this.isloading = true;
      this.$store
        .dispatch("app-employees/fetchDesignationsList")
        .then((response) => {
          this.designationOptions = response.data.data;
          this.isloading = false;
        })
        .catch((error) => {
          this.isloading = false;
        });
    },
    fetchRolesList() {
      this.isloading = true;
      this.$store
        .dispatch("app-employees/fetchRolesList")
        .then((response) => {
          this.list_roles = response.data.data;
          this.isloading = false;
        })
        .catch((error) => {
          this.isloading = false;
        });
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${data.item.name}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.item.hashid);
        }
      });
    },
    confirmDeleteMultipleRecords() {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${this.selectedEmployees.length} Employee, This cannot be undone`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              "app-employees/removeMultipleEmployees",
              this.selectedEmployees
            )
            .then((res) => {
              this.isDeleteMultipleChecked = false;
              this.selectedEmployees = [];
              if (res.data.success) {
                this.refetchData();
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Deleted Successfully",
                //     icon: "BellIcon",
                //     variant: "success",
                //     text: res.data.message,
                //   },
                // });
                this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Deleted Successfully',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
              } else {
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Employee Deletion failed",
                //     icon: "BellIcon",
                //     variant: "danger",
                //     text: res.data.message,
                //   },
                // });

                this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Employee Deletion failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
              }
            })
            .catch((err) => console.log(err));
        }
      });
    },
    deleteRecord(id) {
      const self = this;
      this.$store
        .dispatch("app-employees/removeEmployee", id)
        .then((res) => {
          if (res.data.success) {
            self.refetchData();
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Employee Deleted",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: "Employee deleted successfully",
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Employee Deleted',
                icon: 'BellIcon',
                variant: 'success',
                text: "Employee deleted successfully",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          } else {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Employee Deletion failed",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Employee Deletion failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style>
.filepond--panel-center .filepond--panel-root {
  height: 40px !important;
}
</style>

<style lang="scss">
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.filepond--credits {
  display: none;
}
</style>
